<template>
  <b-overlay :show="show" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body>
        <div class="h4 mb-1">
          รายการแนะนำเพื่อน
        </div>

        <div class="d-flex flex-wrap">
          <div class="d-flex align-items-start py-50">
            <div class="btn-disable">
              วันที่ (จาก)
            </div>
            <flat-pickr v-model="dateStart" class="form-control" placeholder="เลือกเวลาเริ่มต้น"
              :config="{ maxDate: new Date().toISOString().split('T')[0] }" />
          </div>

          <div class="d-flex align-items-start py-50">
            <div class="btn-disable">
              วันที่ (ถึง) :
            </div>
            <flat-pickr v-model="dateEnd" class="form-control" placeholder="เลือกเวลาเริ่มต้น"
              :config="{ maxDate: new Date().toISOString().split('T')[0] }" />
          </div>

          <div class="d-flex align-items-start py-50">
            <button class="btn btn-primary ml-lg-25" @click="GetList()">
              ค้นหา
            </button>
          </div>
        </div>
      </b-card-body>

      <b-table striped hover responsive show-empty class="position-relative items-center" :per-page="perPage"
        :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn">
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img src="/box.png" alt="empty" height="40px">
            {{
    scope.emptyFiltered ? "No matching records found" : "ไม่พบข้อมูล"
  }}
          </div>
        </template>

        <template #cell(invited_by_username)="data">
          {{ data.item.invited_by_username ? data.item.invited_by_username : '-' }}
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="8" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions"
            @change="GetList()" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0" @input="GetList()">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BCardBody,
  BOverlay,
  BIconController,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BCardBody,
    BOverlay,
    BIconController,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      searchType: null,
      searchKeyword: null,
      searchByOptions: [
        { value: 'name', text: 'ชื่อ' },
        { value: 'email', text: 'อีเมล' },
        { value: 'phone', text: 'เบอร์โทร' },
      ],
      itemModal: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      activeChart: '3day',
      fields: [
        { key: 'invited_by_tel', label: 'ผู้แนะนำ' },
        { key: 'tel', label: 'ผู้สมัคร' },
        {
          key: 'created_at',
          label: 'วันที่',
          formatter: value => (value
            ? moment(value)
              .tz('Asia/Bangkok')
              .add('hours', 7)
              .format('DD-MM-YYYY HH:mm')
            : ''),
        },
      ],
      items: [],
      UserData: JSON.parse(localStorage.getItem('userData')),
      dateStart: moment().tz('Asia/Bangkok').clone().subtract(3, 'days').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      show: false,
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.GetList()
  },
  methods: {
    async GetList() {
      const obj = {
        start_date: this.dateStart,
        end_date: this.dateEnd,
        page: this.currentPage,
        per_page: this.perPage,
      }
      this.show = true
      try {
        const { data } = await this.$http.post('/history/invitefriend', obj)
        // console.log(data)
        this.totalRows = data.total
        this.items = data.data
        this.show = false
      } catch (e) {
        console.log(e)
        this.show = false
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.btn {
  box-shadow: 0 4px 8px -4px rgba(94, 86, 105, 0.42) !important;
}

.row {
  flex-wrap: wrap;
  flex: 1 1 auto;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
